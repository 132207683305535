import { Socket } from "phoenix";

import User from "@/base/project/user.js";

import random from "@/base/lib/random.js";

import actions from "@/base/store/actions.js";


const getCallbacks = (store) => {
    return {
        onStart() {
            store.dispatch(actions.monitors.setHeartbeatMonitorOn());
        },
        onStop() {
            store.dispatch(actions.monitors.setHeartbeatMonitorOff());
        },
    };
};

class HeartbeatSocket {
    constructor(eventsURL, store) {
        this.id = random.randomHEX();

        this.eventsURL = eventsURL;
        this.store = store;

        this.state = {
            isRunning: false,
            isAuthorized: false,
            isWentOffline: false,
        };

        this.callbacks = getCallbacks(store);

        this.socket = null;

        this.startStoreMonitor();
    }

    /* --- */

    start() {
        if (this.state.isRunning) {
            return;
        }

        // eslint-disable-next-line no-console
        console.log("[Heartbeat]: start monitor");

        this.state.isRunning = true;

        const user = this.store.getState().user;
        const userId = user?.user?.userId || "";
        const session = user?.session || "";

        const socket = new Socket(this.eventsURL, {
            params: {
                session: session,
                id: this.id,
            },
        });

        socket.connect();

        socket.onClose(() => {
            this.state.isWentOffline = true;
        });

        // NOTE: it is a common channel for all users
        const channel = socket.channel(`users:all:${userId}`, {});

        channel.join()
            .receive("ok", (res) => {
                // eslint-disable-next-line no-console
                console.log("[Heartbeat]: joined successfully", res);
            })
            .receive("error", (res) => {
                // eslint-disable-next-line no-console
                console.log("[Heartebat] unable to join", res)
            });

        this.socket = socket;

        this.callbacks.onStart();
    }

    stop() {
        if (!this.state.isRunning) {
            return;
        }

        // eslint-disable-next-line no-console
        console.log("[Heartbeat]: stop monitor");

        this.state.isRunning = false;

        if (this.socket && this.socket.disconnect) {
            this.socket.disconnect();
            this.socket = null;
        }

        this.callbacks.onStop();
    }

    /* --- */

    changeState(isAuthorized) {
        this.state.isAuthorized = isAuthorized;

        if (isAuthorized) {
            this.start();
        } else {
            this.stop();
        }
    }

    onStoreChange(state) {
        const {
            session,
            isUserLoaded,
            user,
        } = state.user;

        if (!session || !isUserLoaded) {
            this.changeState(false);
            return;
        }

        const isAuthorized = !User.isExpired(user);

        this.changeState(isAuthorized);
    }

    startStoreMonitor() {
        this.store.subscribe(() => {
            const state = this.store.getState();
            this.onStoreChange(state);
        });
    }
}

export default HeartbeatSocket;
